import logo from './logo.svg';
import './App.css';
import LanguageInput from './LanguageInput'
import LanguageOutput from './LanguageOutput'
import React, { useState } from 'react';

function App() {
    // const [translationRequest, setTranslationRequest] = useState("");
    const [translationResult, setTranslationResult] = useState("");



    return (
        <div className="App">
            <LanguageInput setTranslationRequest={setTranslationResult}/>
            <LanguageOutput translationRequest={translationResult}/>
        </div>
    );
}

export default App;
