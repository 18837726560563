import * as React from "react";

function LanguageInput({
                           setTranslationRequest,
                       }) {

    function getTranslation(value){
        const request = encodeURIComponent(value)
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        let url = "https://explnr.com/api/v3/translate/from/en/to/zh/word/"+request;
        console.log(url);
        fetch(url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)

                    setTranslationRequest(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    // setTranslationRequest(error);
                    console.log("ERROR"+error)
                }
            )
    }

    return (
        <div>
            <input type='text' name='title'  onChange={e => getTranslation(e.target.value)}/>
        </div>
    );
}

export default LanguageInput