import * as React from "react";

function LanguageOutput({
                            translationRequest,
                        }) {

    console.log(translationRequest);
    console.log("hello");
    if (translationRequest === "") {

        return (<div/>);
    }

    const results = translationRequest["results"] || [];

    return (
        <div>
            <div>
                <div>{translationRequest.original.from} -> {translationRequest.original.to}</div>

                {translationRequest.results.map(item => <div>{item.from} = {item.to}</div>)}
            </div>
        </div>
    );
}

export default LanguageOutput